import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk'
import { WixLocation } from '@wix/thunderbolt-symbols'
import { WidgetWixCodeSdkWixCodeApi } from '..'

export const initializeWidgetAPI = (
	getWixLocation: () => WixLocation,
	initEditorSDK: () => Promise<EditorSDK>
): WidgetWixCodeSdkWixCodeApi => {
	let _componentRef: ComponentRef
	let _editorSdk: EditorSDK

	const getEditorSdk = async (): Promise<EditorSDK> => {
		if (!_editorSdk) {
			_editorSdk = await initEditorSDK()
		}

		return _editorSdk
	}

	const getComponentRef = () => {
		if (!_componentRef) {
			const wixLocation = getWixLocation()
			const url = new URL(wixLocation.url)
			const componenRefSerialized = url.searchParams.get('componentRef')
			if (!componenRefSerialized) {
				throw new Error('could not find serialized component ref')
			}
			_componentRef = JSON.parse(componenRefSerialized)
		}

		return _componentRef
	}

	const getComponentRefConfig = async () => {
		const editorSdk = await getEditorSdk()

		const controllerRef = getComponentRef()
		const controllerData = await editorSdk.document.controllers.getData('token', {
			controllerRef,
		})

		return controllerData.config
	}

	const get = async () => {
		const componetRefConfig = await getComponentRefConfig()
		return componetRefConfig.props || {}
	}

	const set = async (props: any) => {
		const config = await getComponentRefConfig()
		if (props) {
			config.props = { ...config.props, ...props }
		} else {
			config.props = undefined
		}

		const editorSdk = await getEditorSdk()

		await editorSdk.controllers.saveConfiguration('token', {
			controllerRef: getComponentRef(),
			config,
		})

		await editorSdk.application.livePreview.refresh('token', {
			source: 'AFTER_PROP_UPDATE',
			shouldFetchData: false,
		})
	}

	return {
		props: {
			set,
			get,
		},
	}
}
