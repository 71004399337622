import type { ApplicationContextOptions, EditorSDK } from '@wix/platform-editor-sdk'
import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { namespace, WidgetWixCodeSdkHandlers, WidgetWixCodeSdkWixCodeApi } from '..'
import { initializeWidgetAPI } from './widgetApi'
import { getEditorSDKurl } from '@wix/platform-editor-sdk/lib/loader'

export function WidgetSdkFactory({
	platformUtils,
}: WixCodeApiFactoryArgs<never, never, WidgetWixCodeSdkHandlers>): { [namespace]: WidgetWixCodeSdkWixCodeApi } {
	const initEditorSdk = async (): Promise<EditorSDK> => {
		const channel = new MessageChannel()

		const windowSdk = platformUtils.wixCodeNamespacesRegistry.get('window')
		const wixLocation = platformUtils.wixCodeNamespacesRegistry.get('location')

		const url = new URL(wixLocation.url)
		const applicationIdParam = url.searchParams.get('applicationId')
		const appDefinitionIdParam = url.searchParams.get('appDefinitionId')

		if (!applicationIdParam || !appDefinitionIdParam) {
			throw new Error('Could not find application id params')
		}

		const applicationId = Number(url.searchParams.get('applicationId'))

		if (isNaN(applicationId)) {
			throw new Error('Could not parse applicationId')
		}

		const sdkVersion = url.searchParams.get('sdkVersion')
		if (!sdkVersion) {
			throw new Error('Could not find sdkVersion')
		}

		const contextOptions: ApplicationContextOptions = {
			applicationId,
			appDefinitionId: appDefinitionIdParam,
		}

		channel.port1.onmessage = (ev) => {
			windowSdk.postMessage(ev.data, undefined, undefined, [...ev.ports])
		}

		const sdkScriptSrc = getEditorSDKurl(url)

		self.importScripts(sdkScriptSrc)

		const editorSDK = self.editorSDK
		await editorSDK.__initWithTarget(channel.port2, [], '')

		return editorSDK.getBoundedSDK(contextOptions)
	}
	const getWixLocation = () => {
		return platformUtils.wixCodeNamespacesRegistry.get('location')
	}

	const widgetAPI = initializeWidgetAPI(getWixLocation, initEditorSdk)

	return {
		[namespace]: widgetAPI,
	}
}
