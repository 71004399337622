import {
	registry,
	createRegistryAPI,
	ILibraryTopology,
	ICorvidRegistryAPI,
	ICorvidRegistryRuntime,
	ICorvidComponentModel,
	ICorvidComponentLoader,
	IRegistryManifestContext,
} from '@wix/editor-elements-registry/corvid'
import { createRegistryInstanceCache } from '@wix/editor-elements-registry/createRegistryInstanceCache'
import { REGISTRY_RUNTIME_GLOBAL_PROP_NAME } from './runtime'
import _ from 'lodash'

import { ComponentsRegistryError, ComponentsRegistryErrorTypes } from './errors'
import {
	SnapshotCreatorResource,
	SnapshotResourceType,
	FetchResponse,
	PlatformLogger,
	SnapshotResource,
} from '@wix/thunderbolt-symbols'

type IComponentsSDKRegistryAPI = ICorvidRegistryAPI
type IComponentSDKLoaders = Record<string, ICorvidComponentLoader>
type IComponentSDKs = Record<string, ICorvidComponentModel['sdk']['factory']>
type IComponentsRegistryPlatformRuntime = ICorvidRegistryRuntime

export {
	ILibraryTopology,
	IComponentsSDKRegistryAPI,
	IComponentsRegistryPlatformRuntime,
	IComponentSDKLoaders,
	IComponentSDKs,
}

export interface IComponentSDKLoader {
	sdkTypeToComponentTypes: Record<string, Array<string>>
	loadComponentSdks: (componentTypes: Array<string>, logger: PlatformLogger) => Promise<IComponentSDKs>
}

export interface IComponentsRegistryPlatform {
	getRegistryAPI: () => IComponentsSDKRegistryAPI
	getComponentsSDKsLoader: () => IComponentSDKLoader
}

export interface IPlatformRegistryParameters {
	libraries: Array<ILibraryTopology | IRegistryManifestContext>
	mode?: 'lazy' | 'eager'
	fetchFn?: (url: string) => Promise<FetchResponse>
	loadFallbackSDKModule?: () => Promise<ICorvidComponentModel>
}

const cache = createRegistryInstanceCache<ICorvidRegistryAPI>()

async function getRegistryAPI({ libraries, fetchFn, mode }: IPlatformRegistryParameters): Promise<ICorvidRegistryAPI> {
	/**
	 * TODO: remove this .map
	 * after `createRegistryInstanceCache` is updated at registry
	 */
	const key = libraries.map((library) => {
		if (library.hasOwnProperty('topology')) {
			return (library as IRegistryManifestContext).topology
		} else {
			return library as ILibraryTopology
		}
	}) as Array<ILibraryTopology>

	return cache.getRegistryAPI({
		libraries: key,
		shouldCache: !process.env.browser,
		factory: () => {
			const transport = fetchFn
				? {
						async fetchLibraryManifest(resourceURL: string) {
							const response = await fetchFn(resourceURL)
							return response.json()
						},
						async fetchLibraryRuntime(resourceURL: string) {
							const response = await fetchFn(resourceURL)
							return response.text()
						},
						async fetchLibraryHotBundle(resourceURL: string) {
							const response = await fetchFn(resourceURL)
							return response.text()
						},
						async fetchComponentsBundle(resourceURL: string) {
							const response = await fetchFn(resourceURL)
							return response.text()
						},
				  }
				: undefined

			return createRegistryAPI(registry, {
				mode,
				libraries: libraries ? libraries : [],
				transport,
				globals: {
					_,
					lodash: _,
				},
			})
		},
	})
}

export async function createComponentsRegistryPlatform(
	options: IPlatformRegistryParameters
): Promise<IComponentsRegistryPlatform> {
	const { loadFallbackSDKModule } = options

	const registryAPI = await getRegistryAPI(options)
	const components = registryAPI.getComponents()

	const sdkTypeToComponentTypes: Record<string, Array<string>> = {}

	Object.keys(components).forEach((componentType) => {
		const loader = components[componentType]

		const key = loader.statics?.sdkType ?? componentType

		if (!sdkTypeToComponentTypes[key]) {
			sdkTypeToComponentTypes[key] = []
		}

		sdkTypeToComponentTypes[key].push(componentType)
	})

	return {
		getComponentsSDKsLoader() {
			/**
			 * Still missing features:
			 *
			 * - timeout https://jira.wixpress.com/browse/WCR-129
			 */
			return {
				sdkTypeToComponentTypes: { ...sdkTypeToComponentTypes },
				async loadComponentSdks(componentTypes, logger) {
					const [existingComponents, unexistingComponents] = _.partition(componentTypes, (componentType) =>
						registryAPI.isComponentExist(componentType)
					)

					try {
						const shouldLoadFallback = loadFallbackSDKModule && unexistingComponents.length !== 0

						const [models, fallbackSDKModule] = await Promise.all([
							registryAPI.loadComponents(existingComponents),
							shouldLoadFallback ? loadFallbackSDKModule!() : null,
						])

						const componentSDKs: IComponentSDKs = {}

						if (fallbackSDKModule) {
							unexistingComponents.forEach((componentType) => {
								componentSDKs[componentType] = fallbackSDKModule.sdk as any
							})
						}
						Object.keys(models).forEach((componentType) => {
							/**
							 * Backward compatibility since we changed the component SDK model
							 * In future should `models[componentType].sdk.factory`
							 */
							const sdk = models[componentType].sdk
							componentSDKs[componentType] =
								typeof sdk.factory === 'function' ? sdk.factory : (sdk as any)
						})

						return componentSDKs
					} catch (e) {
						const error = new ComponentsRegistryError(
							`${e instanceof Error ? e.message : e}\nSDKs that failed to load: ${unexistingComponents}`,
							ComponentsRegistryErrorTypes.COMPONENT_LOADING_ERROR
						)

						error.stack = e instanceof Error ? e.stack : error.stack

						logger.captureError(error, {
							tags: {
								editorElements: true,
								method: 'loadComponentSdks',
							},
							extra: { compTypes: componentTypes, error },
						})

						return Promise.reject(error)
					}
				},
			}
		},
		getRegistryAPI() {
			return registryAPI
		},
	}
}

export async function getSnapshotResources({
	libraries,
	fetchFn,
}: {
	libraries: Array<ILibraryTopology>
	fetchFn: (url: string) => Promise<FetchResponse>
}): Promise<{
	resourcesUniqKeys: Array<string>
	snapshotResources: Array<SnapshotCreatorResource | SnapshotResource>
}> {
	const instance = await createComponentsRegistryPlatform({
		mode: 'lazy',
		libraries,
		fetchFn,
	})

	const registryAPI = instance.getRegistryAPI()

	return {
		resourcesUniqKeys: libraries.map(({ url, namespace }) => `${url}~${namespace}`),
		snapshotResources: [
			...registryAPI.getHostModelJSAssets().map<SnapshotResource>((asset) => {
				return {
					type: SnapshotResourceType.ScriptURL,
					scriptUrl: asset.url,
				}
			}),
			{
				type: SnapshotResourceType.ScriptCode,
				scriptCode: `self.${REGISTRY_RUNTIME_GLOBAL_PROP_NAME} = JSON.parse('${JSON.stringify(
					registryAPI.getRegistryRuntime()
				)}')`,
				scriptUrl: 'registry-runtime.js',
			},
		],
	}
}
