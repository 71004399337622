import { V2OrdersOrder } from '@wix/ambassador-membership-api/types'
import { CancelAt, ListOrdersRequest, Order } from '@wix/ambassador-pricing-plans-member-orders/types'

import { PricingPlansAmbassador } from './pricingPlansAmbassador'

export type CancelAtEnum = CancelAt.IMMEDIATELY | CancelAt.NEXT_PAYMENT_DATE

export class PricingPlansApi {
	constructor(private readonly ambassador: PricingPlansAmbassador) {}

	startOnlineOrder = async (planId: string, startDate?: Date): Promise<V2OrdersOrder> => {
		const response = await (await this.ambassador.checkoutService()).startOnlineOrder({ planId, startDate })
		return response.order!
	}

	requestMemberOrderCancellation = async (id: string, cancelAt: CancelAtEnum): Promise<void> => {
		if (cancelAt !== CancelAt.IMMEDIATELY && cancelAt !== CancelAt.NEXT_PAYMENT_DATE) {
			throw new Error('cancelAt must be either IMMEDIATELY or NEXT_PAYMENT_DATE')
		}
		await (await this.ambassador.memberOrdersService()).requestCancellation({ id, cancelAt })
	}

	listCurrentMemberOrders = async (options: ListOrdersRequest): Promise<Array<Order>> => {
		const response = await (await this.ambassador.memberOrdersService()).listOrders(options)
		return response.orders!
	}
}
