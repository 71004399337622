import { PlatformEnvData, PlatformLogger } from '@wix/thunderbolt-symbols'
import { FallbackCorvidModel } from '@wix/editor-elements-corvid-utils'

import { IComponentSDKLoader, createComponentsRegistryPlatform } from './platform'
import { ComponentsRegistryError, ComponentsRegistryErrorTypes } from './errors'
import { REGISTRY_RUNTIME_GLOBAL_PROP_NAME } from './runtime'

export const getComponentsSDKLoader = async ({
	platformEnvData,
	loadModule,
	logger,
	componentSdksUrl,
}: {
	platformEnvData: PlatformEnvData
	loadModule: <T>(url: string) => T
	logger: PlatformLogger
	componentSdksUrl?: string
}): Promise<IComponentSDKLoader> => {
	/**
	 * `platformEnvData.componentsRegistry` exists only when `specs.thunderbolt.componentsRegistry` experiment is enabled
	 *
	 * Once registry experiment is merged, remove `if`
	 * https://jira.wixpress.com/browse/WCR-365
	 */
	if (platformEnvData.componentsRegistry) {
		const runtime = self[REGISTRY_RUNTIME_GLOBAL_PROP_NAME]
		const libraries = runtime ? runtime.libraries : platformEnvData.componentsRegistry.librariesTopology

		const mode = platformEnvData.componentsRegistry.mode

		try {
			return logger.runAndReport(`import_scripts_componentSdks`, async () => {
				const componentsRegistryPlatform = await createComponentsRegistryPlatform({
					libraries,
					mode,
					loadFallbackSDKModule: () => FallbackCorvidModel.loadSDK() as any,
				})

				return componentsRegistryPlatform.getComponentsSDKsLoader()
			})
		} catch (e) {
			logger.captureError(
				new ComponentsRegistryError(
					'could not create platform components registry',
					ComponentsRegistryErrorTypes.INITIALIZATION_ERROR
				),
				{
					groupErrorsBy: 'values',
					tags: { method: 'getComponentsSDKLoader' },
					extra: { libraries, mode, error: e },
				}
			)

			return {
				sdkTypeToComponentTypes: {},
				loadComponentSdks: () => {
					throw new ComponentsRegistryError(
						'could not load components. platform components registry was initialized with error',
						ComponentsRegistryErrorTypes.COMPONENT_LOADING_ERROR
					)
				},
			}
		}
	} else if (componentSdksUrl) {
		try {
			return logger.runAndReport(`import_scripts_componentSdks`, () =>
				loadModule<IComponentSDKLoader>(componentSdksUrl)
			)
		} catch (e) {
			logger.captureError(
				new ComponentsRegistryError(
					'could not load component SDKs loader',
					ComponentsRegistryErrorTypes.INITIALIZATION_ERROR
				),
				{
					groupErrorsBy: 'values',
					tags: { method: 'getComponentsSDKLoader' },
					extra: { componentSdksUrl, error: e },
				}
			)

			return {
				sdkTypeToComponentTypes: {},
				loadComponentSdks: () => {
					throw new ComponentsRegistryError(
						'could not load components. component SDKs loader was not loaded',
						ComponentsRegistryErrorTypes.COMPONENT_LOADING_ERROR
					)
				},
			}
		}
	} else {
		throw new ComponentsRegistryError(
			'It is not possible to crete Components SDK loader. Registry experiment is off and `componentSdksUrl` was not provided',
			ComponentsRegistryErrorTypes.INVALID_ARGUMENTS
		)
	}
}
