import { BILoggerFactory, commonBiLoggerFactory } from '@wix/thunderbolt-commons'
import { PlatformEnvData, SessionServiceAPI } from '@wix/thunderbolt-symbols'

export const platformBiLoggerFactory = ({ sessionService, factory }: { sessionService: Pick<SessionServiceAPI, 'getVisitorId' | 'getSiteMemberId'>; factory: BILoggerFactory }) => {
	const createBiLoggerFactory = ({
		biData,
		endpoint,
		factoryCreator,
		muteBi,
	}: {
		biData: PlatformEnvData['bi']
		endpoint?: string
		factoryCreator: typeof commonBiLoggerFactory[keyof typeof commonBiLoggerFactory]
		muteBi: boolean
	}) => {
		const { msId: msid, viewerSessionId, requestId, initialTimestamp, initialRequestTimestamp, dc, is_rollout, isCached, rolloutData, pageData, viewerVersion, isjp, btype } = biData

		const biStore = {
			msid,
			viewerSessionId,
			requestId,
			initialTimestamp,
			initialRequestTimestamp,
			dc,
			is_rollout,
			isCached,
			rolloutData,
			pageData,
			viewerVersion,
			is_headless: isjp,
			is_headless_reason: btype,
		}
		return factoryCreator({ biStore, sessionManager: sessionService, muteBi, endpoint, fetch: self.fetch, factory })
	}

	/**
	 * Base BI logger factory, should be used as a basis for any BI logger in the Viewer Platform
	 *
	 * - Initialized with base defaults.
	 * - Any additional defaults should be added via specialized factories, like FedOps,
	 *   and only after making sure the BI schema supports them.
	 *
	 * Please use #bi-logger-support for any questions
	 */
	const createBaseBiLoggerFactory = (biData: PlatformEnvData['bi'], endpoint?: string) => {
		return createBiLoggerFactory({
			biData,
			endpoint,
			factoryCreator: commonBiLoggerFactory.createBaseBiLoggerFactory,
			muteBi: biData.muteBi,
		})
	}

	/**
	 * BI logger factory for Viewer Platform FedOps
	 *
	 * - Initialized with base defaults + defaults which are supported by FedOps BI events
	 *   https://bo.wix.com/bi-catalog-webapp/#/sources/72
	 *
	 * - Any additional defaults should be added only after making sure the BI schema supports them
	 *
	 * Please use #bi-logger-support for any questions
	 */
	const createBiLoggerFactoryForFedops = (biData: PlatformEnvData['bi'], muteBi = biData.muteFedops) => {
		return createBiLoggerFactory({
			biData,
			factoryCreator: commonBiLoggerFactory.createBiLoggerFactoryForFedops,
			muteBi,
		})
	}

	return {
		createBaseBiLoggerFactory,
		createBiLoggerFactoryForFedops,
	}
}
