import type { ClientSpecMapAPI, ModelsAPI } from '@wix/thunderbolt-symbols'

export const isWixDataRequired = ({
	modelsApi,
	clientSpecMapApi,
}: {
	modelsApi: ModelsAPI
	clientSpecMapApi: ClientSpecMapAPI
}) => {
	const applications = modelsApi.getApplications()
	const isAppRunning = (appDefId?: string) => Boolean(appDefId && applications[appDefId])

	const isWixCodeRunning = isAppRunning(clientSpecMapApi.getWixCodeAppDefinitionId())
	const isDatabindingRunning = isAppRunning(clientSpecMapApi.getDataBindingAppDefinitionId())
	const isBlocksRunning = clientSpecMapApi.getBlocksAppsAppDefinitionIds().some(isAppRunning)

	// eslint-disable-next-line prettier/prettier
	return (isWixCodeRunning || isDatabindingRunning || isBlocksRunning) && false // temporary workaround
}
